/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { MDBBtn, MDBContainer, MDBInput } from "mdb-react-ui-kit";
import Lottie from "lottie-react";

import { AccountActivationProps } from "../restaurant.config";
import { Stages, CustomToolTip, SuccessModal } from "../../../components";
import Success from "../../../assets/successful.json";
import { useAppSelector } from "../../../app/hooks";
import { selectRestaurantAuthStatus } from "../../../features/slice/restaurant/authStatusSlice";

const ContactInformation = (props: AccountActivationProps) => {
  const { index, setIndex, steps, setFormData, formData } = props;
  const { isActivated } = useAppSelector(selectRestaurantAuthStatus);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!isActivated) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [isActivated]);
  //   formData.social_links

  // const handleSocialUpdate = (tag: string, value: any) => {
  //   switch (tag) {
  //     case "instagram":
  //       setFormData((prevState) => {
  //         return {
  //           ...prevState,
  //           social_links: { ...prevState.social_links, instagram: value },
  //         };
  //       });
  //       break;
  //     case "facebook":
  //       setFormData((prevState) => {
  //         return {
  //           ...prevState,
  //           social_links: { ...prevState.social_links, facebook: value },
  //         };
  //       });
  //       break;
  //     case "x":
  //       setFormData((prevState) => {
  //         return {
  //           ...prevState,
  //           social_links: { ...prevState.social_links, X: value },
  //         };
  //       });
  //       break;
  //     // case 'linkedIn':
  //     //     setFormData(
  //     //         prevState => {
  //     //             return {
  //     //                 ...prevState,
  //     //                 social_links: { ...prevState.social_links, linkedIn: value }
  //     //             }
  //     //         }
  //     //     )
  //     //     break;
  //     default:
  //       break;
  //   }
  // };

  const handleProceed = (e: any) => {
    e.preventDefault();
    setIndex(index + 1);
  };

  return (
    <div>
      <MDBContainer className="account-activation">
        <h1>Account Activation</h1>
        <Stages steps={steps} step={index} />
        <form className="contact-info-container" onSubmit={handleProceed}>
          <div>
            <label htmlFor="email">
              Email Address &nbsp;
              <CustomToolTip
                message="Include an alternate contact email address (not required)"
                color="#FFAE0A"
                size={15}
              />
            </label>
            <MDBInput
              id="email"
              type="email"
              size="lg"
              required
              value={formData.email}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  email: e.target.value.trim().toLowerCase(),
                }));
                sessionStorage.setItem("contract_email", e.target.value);
              }}
            />
          </div>
          <br />

          {/* <div>
                <label htmlFor="phone">
                    Phone Number &nbsp;
                    <CustomToolTip
                        message="Include an alternate phone number (not required)"
                        color= '#FFAE0A'
                        size= {15} 
                    />
                </label>
                <MDBInput 
                    id='phone' 
                    type='tel' 
                    size='lg'
                    onChange={e => setFormData({...formData, phone: e.target.value})}
                    // value={phone} 
                />
            </div><br/> */}

          {/* <div className="social-links">
            <h4>Social links</h4>
            <div className="links-container">
              <div className="flex-container">
                <input
                  id="facebook"
                  type="url"
                  placeholder="https://www.facebook.com/..."
                  onChange={(e) =>
                    handleSocialUpdate("facebook", e.target.value.toLowerCase())
                  }
                />
                <input
                  id="instagram"
                  type="url"
                  placeholder="https://www.instagram.com/..."
                  onChange={(e) =>
                    handleSocialUpdate(
                      "instagram",
                      e.target.value.toLowerCase()
                    )
                  }
                />
              </div>
              <div className="flex-container">
                <input
                  id="twitter"
                  type="url"
                  placeholder="https://www.twitter.com/..."
                  onChange={(e) =>
                    handleSocialUpdate("x", e.target.value.toLowerCase())
                  }
                />
            
              </div>
            </div>
          </div> */}

          <MDBBtn className="proceed-btn" type="submit">
            Proceed &rarr;
          </MDBBtn>
        </form>
      </MDBContainer>
      {showMessage && (
        <SuccessModal
          title={"Success!"}
          icon={<Lottie animationData={Success} loop={false} />}
          message={
            <div className="text-center">
              Your account has been created successfully! <br />
              <br /> Activate your account to commence operational activities by
              providing the necessary information. <br /> <br />{" "}
              {/* <button
                className="edit-button"
                onClick={() => setShowMessage(false)}
              >
                Proceed
              </button> */}
            </div>
          }
        />
      )}
    </div>
  );
};

export default ContactInformation;
