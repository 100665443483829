/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { AddAPhotoOutlined } from "@mui/icons-material";
import { MDBContainer, MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/restaurant-portal/PageLayout";
import {
  useGetMealsCategoriesQuery,
  useLazyGetSingleMealQuery,
  useUpdateMealMutation,
  useUploadMealImageMutation,
} from "../../../features/services/restaurant/service";
import { toast } from "react-toastify";
import Loader from "../../../components/loader";
import axios from "axios";
import { FormControl, TextField } from "@mui/material";

const menu_list = [
  {
    category: "combos",
    menu_list: [
      {
        id: "jksdkgsfodh",
        image: "",
        name: "sapa meal",
        price: 700,
        description: "Native rice & beef",
      },
      {
        id: "klhhjkgho",
        image: "",
        name: "Sapa Meal",
        price: 700,
        description: "Native rice & beef",
      },
      {
        id: "lkguiogk",
        image: "",
        name: "Sapa Meal",
        price: 700,
        description: "Native rice & beef",
      },
      {
        id: "",
        image: "",
        name: "",
        price: 700,
        description: "",
      },
    ],
  },
  {
    category: "combos",
    menu_list: [
      {
        id: "",
        image: "",
        name: "",
        price: 700,
        description: "",
      },
    ],
  },
  {
    category: "combos",
    menu_list: [
      {
        id: "",
        image: "",
        name: "",
        price: 700,
        description: "",
      },
    ],
  },
];

export const RestaurantUpdateItem = () => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState<string>("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [uploading, setUploading] = useState(false);
  const [takeAwayPrice, setTakeAwayPrice] = useState<string>("");
  const [withTakeAway, setWithTakeAway] = useState(true);

  const { meal_id } = useParams();
  const navigate = useNavigate();
  // Mutations and Queries
  const [
    getSingleMeal,
    { data: initialMealDetails, isLoading, error: error_gettingMealDetails },
  ] = useLazyGetSingleMealQuery();

  const [uploadMealImage, { uploadStatus, isUploadingImg, errorUploadingImg }] =
    useUploadMealImageMutation({
      selectFromResult: ({ data, isLoading, error }) => ({
        uploadStatus: data,
        isUploadingImg: isLoading,
        errorUploadingImg: error,
      }),
    });

  const {
    data: categories,
    isLoading: loading_categories,
    error: error_fetching_categories,
  } = useGetMealsCategoriesQuery();
  const [
    updateMeal,
    {
      data: updatedMeal,
      isLoading: updatingMeal,
      isSuccess: mealUpdateSuccess,
      error,
    },
  ] = useUpdateMealMutation();

  //   FUNCTIONS
  const showFileInput = () => hiddenFileInput.current?.click();
  const handleUpload = () => {
    setUploading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/image-upload`,
        { image: selectedImage },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res: any) => {
        setUploading(false);
        setUploadedImage(res?.data?.url);
        toast.success("Image uploaded successfully");
      })
      .catch((err) => {
        setUploading(false);
        toast.error("Error uploading image");
      });
  };
  //Initial image upload function
  // const handleUpload = async () => {
  //   if (!selectedImage) return;
  //   const formData = new FormData();
  //   formData.append("image", selectedImage);
  //   try {
  //     await uploadMealImage(formData).unwrap();
  //   } catch (error) {
  //     toast.error("Error! Ensure file size is less than 250kb");
  //   }
  // };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    //add uploadedImage to this check
    if (name && price && selectedCategory) {
      updateMeal({
        body: {
          name,
          price: {
            amount: Number(price),
            currency_code: "NGN",
          },
          description,
          image_url: uploadedImage,
          take_away_price: Number(takeAwayPrice),
          with_take_away: withTakeAway ? "true" : "false",
          // image_url:
          //   "https://res.cloudinary.com/sophdev/image/upload/v1716749886/peak-service/cocnut%20rice.jpg",
          meal_category: selectedCategory,
        },
        meal_id,
      });
    } else {
      toast.error("All input fields are required");
    }
  };

  // useEffects
  useEffect(() => {
    getSingleMeal(meal_id);
  }, []);
  useEffect(() => {
    if (mealUpdateSuccess) {
      toast.success("Meal updated successfully");
      navigate("/restaurant-portal/menu-manager");
    } else if (error) {
      // toast.error(error?.data?.error);
      toast.error("Error updating meal! Try again");
    }
  }, [mealUpdateSuccess, updatedMeal, error]);
  useEffect(() => {
    setUploadedImage(initialMealDetails?.image_url);
    setName(initialMealDetails?.name);
    setPrice(initialMealDetails?.price?.amount);
    setDescription(initialMealDetails?.description);
    setSelectedCategory(initialMealDetails?.meal_category);
    setWithTakeAway(initialMealDetails?.with_take_away);
    setTakeAwayPrice(initialMealDetails?.take_away_price);
  }, [initialMealDetails]);
  useEffect(() => {
    if (selectedImage) {
      handleUpload();
    }
  }, [selectedImage]);
  useEffect(() => {
    setUploadedImage(uploadStatus?.url);
  }, [uploadStatus, errorUploadingImg]);

  return (
    <PageLayout>
      <MDBContainer fluid={true} breakpoint="md" className="add-menu-container">
        <Link to="/restaurant-portal/menu-manager">
          <h1>&larr; Update item</h1>
        </Link>
        <section>
          <form onSubmit={handleSubmit}>
            <div className="photo-upload">
              {uploading ? (
                <Loader
                  height="27px"
                  width="27px"
                  borderTopColor="#FFAE0A"
                  borderTopWidth="5px"
                  borderWidth="5px"
                />
              ) : (
                <img
                  src={uploadedImage}
                  alt=""
                  width="100%"
                  height="250px"
                  onClick={showFileInput}
                />
              )}
            </div>
            <div className="label-texts">
              <p className="first">
                This image will be displayed to the customers
              </p>
              <p className="second">It's recommended to upload quality image</p>
            </div>
            <input
              type="file"
              accept="image/*"
              className="hidden-input"
              ref={hiddenFileInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target?.files && setSelectedImage(e.target?.files[0])
              }
            />
            <div className="inputs-container">
              <MDBInput
                label="Meal name"
                id="mealName"
                type="text"
                size="lg"
                onChange={(e) => setName(e.target.value.trim().toLowerCase())}
                value={name}
              />
              <br />
              <MDBInput
                label="Price"
                id="price"
                type="number"
                size="lg"
                onChange={(e) => {
                  const value = e.target.value;
                  setPrice(value === "0" ? "" : value);
                }}
                value={price}
                // inputProps={{ min: "0" }}
              />
              <br />
              <select
                className="w-100 shadow-0 drop-down-select"
                placeholder="Category"
                value={selectedCategory}
                onChange={(e) =>
                  setSelectedCategory(e.target.value.toLowerCase())
                }
              >
                <option value={initialMealDetails?.meal_category}>
                  {initialMealDetails?.meal_category}
                </option>
                {/*  replace type any with categories type */}
                {categories?.map((item: any) => (
                  <option key={item?._id} value={item?.name.toLowerCase()}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <br />
              <FormControl fullWidth style={{ marginBottom: "1.3rem" }}>
                <TextField
                  label="Take away price"
                  id="takeaway"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value;
                    setTakeAwayPrice(value === "0" ? "" : value);
                  }}
                  value={takeAwayPrice}
                  // inputProps={{ min: "0" }}
                />
              </FormControl>
              <br />
              <div className="take-away">
                <input
                  type="checkbox"
                  checked={withTakeAway}
                  onChange={() => setWithTakeAway(!withTakeAway)}
                />
                <label>With take away</label>
              </div>
              <br />
              <MDBTextArea
                label="Description (optional)"
                id="description"
                className="text-area"
                rows={4}
                onChange={(e) => setDescription(e.target.value.trim().toLowerCase())}
                value={description}
                style={{ cursor: "text" }}
              />

              <button className="w-100 save-button" type="submit">
                {updatingMeal ? (
                  <Loader
                    height="27px"
                    width="27px"
                    borderTopColor="#FFAE0A"
                    borderTopWidth="5px"
                    borderWidth="5px"
                  />
                ) : (
                  "UPDATE ITEM"
                )}
              </button>
            </div>
          </form>
        </section>
      </MDBContainer>
    </PageLayout>
  );
};