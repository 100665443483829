import { Dispatch, SetStateAction, useState } from "react";
import { Add } from "@mui/icons-material";
import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";

import { AuthLayout } from "../../../components";
import { TimeSelectionModal } from "../../../components/timeModal";
import { FormDataProps } from "../restaurant.config";
import AddressInput from "../../../components/addressInput";
// import axios from "axios";
import Loader from "../../../components/loader";

const data = {
  headingText: "Hello there!",
  subText:
    "Create an account with us and we'll help you reach a wider audience and boost your sales. If you already have an account with us, sign in here.",
  btnText: "SIGN IN",
  btnPath: "/restaurant-portal/signin",
};

type Props = {
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  formData: FormDataProps;
  setFormData: Dispatch<SetStateAction<FormDataProps>>;
};

type BusinessHoursProps = {
  day: string;
  open: string;
  close: string;
};

export const SignUpRestaurantInfo = (props: Props) => {
  const { index, setIndex, formData, setFormData } = props;
  const [restaurantName, setRestaurantName] = useState(formData.name || "");
  // const [addressCode, setAddressCode] =
  //   useState<CreateAddressPayloadProps | null>(null);
  const [phone, setPhone] = useState(formData.phone || "");
  const [businessHours, setBusinessHours] = useState<BusinessHoursProps[]>(
    formData.days_open || []
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    address: "",
    phone: "",
  });
  const [addressSearchBox, setAddressSearchBox] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  // handle the google map places display and  selection
  const handlePlaceChanged = () => {
    if (addressSearchBox !== null) {
      const places = addressSearchBox?.getPlaces();
      if (places.length > 0) {
        const place = places[0];
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { placeId: place.place_id },
          (results: any, status: string) => {
            if (status === "OK") {
              if (results[0]) {
                setSelectedPlace({
                  address: results[0].formatted_address,
                  type: results[0].geometry.location_type,
                  latitude: place.geometry.location.lat(),
                  longitude: place.geometry.location.lng(),
                });
              }
            }
          }
        );
      }
    } else {
      setInputErrors({
        ...inputErrors,
        address: "Address field must not empty",
      });
    }
  };
  // display address box when page loads
  const handleLoad = (ref: any) => {
    setAddressSearchBox(ref);
  };

  const toggleShow = (e: any) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const handlePhone = (e: any) => {
    const value = e.target.value;
    setPhone(value);
    if (value.length < 11) {
      setInputErrors({
        ...inputErrors,
        phone: "Phone number must be 11 characters",
      });
    } else {
      setInputErrors({ ...inputErrors, phone: "" });
    }
  };

  const handlePrevious = () => setIndex(index - 1);

  const handleProceed = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (
      businessHours.length > 0 &&
      selectedPlace !== null &&
      inputErrors.address === "" &&
      inputErrors.phone === ""
    ) {
      // Preserve all existing form data while updating step 2 fields
      setFormData((prevData) => ({
        ...prevData,
        name: restaurantName,
        address: selectedPlace.address,
        location: {
          Type: selectedPlace.type,
          Coordinates: [selectedPlace.longitude, selectedPlace.latitude], //[long, lat]
        },
        phone: phone,
        days_open: businessHours,
      }));
      setIndex(index + 1);
      setError(false);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const handleRestaurantName = (_arg: string) => {
    const r_name = _arg.trim().toLowerCase();
    setRestaurantName(r_name);
  };

  // useEffect(() => {
  //     setLoading(false);
  //     if(addressCode){
  //         setFormData({
  //             ...formData,
  //             name: restaurantName,
  //             address: addressCode?._id,
  //             phone: phone,
  //             days_open: businessHours
  //         });
  //         setIndex(index + 1);
  //         setError(false);
  //     }
  // }, [ addressCode ])

  return (
    <>
      <AuthLayout
        headingText={data.headingText}
        subText={data.subText}
        btnText={data.btnText}
        btnPath={data.btnPath}
      >
        <div className="restaurant-information-container">
          <h1>Create Account</h1>
          <p className="desc-text">Step 2 of 3 : Restaurant information</p>
          <form className="mt-5" onSubmit={handleProceed}>
            <MDBInputGroup size="lg">
              <input
                placeholder="Restaurant Name"
                type="text"
                className="form-control"
                onChange={(e) => handleRestaurantName(e.target.value)}
                value={restaurantName}
                required
              />
            </MDBInputGroup>

            <div className="mt-4">
              <AddressInput
                placeholder="Restaurant Address"
                type="text"
                onLoad={handleLoad}
                onPlaceChanged={handlePlaceChanged}
                className="form-control"
                required={true}
              />

              <small className="">
                Kindly select closest address from the suggested addresses.
              </small>
            </div>

            <MDBInputGroup size="lg" className="mt-4">
              <input
                placeholder="Phone number"
                type="tel"
                maxLength={11}
                className="form-control"
                onChange={handlePhone}
                value={phone}
                required
              />
            </MDBInputGroup>
            {inputErrors.phone && (
              <small className="error-text">{inputErrors.phone}</small>
            )}

            <div className="add-opening-hours mt-4">
              <button onClick={toggleShow}>
                <Add /> <span>ADD OPENING HOURS</span>
              </button>
            </div>
            {error && (
              <p className="error-text mt-3 mb-0">
                Business hours must be included
              </p>
            )}
            {businessHours.length > 0 && (
              <div className="opening-days-display mt-3">
                <ul className="selected-days">
                  {businessHours.map((item, index) => (
                    <li
                      key={index}
                    >{`${item.day}: ${item.open} - ${item.close}`}</li>
                  ))}
                </ul>
                <br />
                {/* <div className="selected-duration">
                  {businessHours.day.toLowerCase()}
                </div> */}
              </div>
            )}
            <div className="btns">
              <MDBBtn
                onClick={handlePrevious}
                className="back-btn mt-4 btn btn-lg"
              >
                &larr; BACK
              </MDBBtn>
              <MDBBtn className="next-btn mt-4 btn" size="lg" type="submit">
                {loading ? (
                  <Loader
                    height="27px"
                    width="27px"
                    borderTopColor="#FFAE0A"
                    borderTopWidth="5px"
                    borderWidth="5px"
                  />
                ) : (
                  <>NEXT &rarr;</>
                )}
              </MDBBtn>
            </div>
          </form>
        </div>
      </AuthLayout>
      {/* Add opening hours modal  */}
      {showModal && (
        <TimeSelectionModal
          setModalDisplay={setShowModal}
          setBusinessTime={setBusinessHours}
        />
      )}
    </>
  );
};

/* <MDBInputGroup size='lg' className='mt-4'>
        <input 
            placeholder='Restaurant Address' 
            type='text' 
            className='form-control' 
            minLength={24}
            // value={restaurantAddress}
            onChange={handleRestaurantAddress}
            required
        />
    </MDBInputGroup>
    { inputErrors.address && <small className='error-text'>{inputErrors.address}</small> } */
